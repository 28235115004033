<template>
  <div class="news-detail" v-scrollintoview>
    <div class="view-container news-detail-content" >
      <div class="content-top">
        <div class="content-top-title">{{ detailData.newsTitle }}</div>
        <div class="content-top-footer">
          <div class="content-top-footer-left">{{ detailData.creatorUserName }}</div>
          <div class="content-top-footer-right">{{ $t('发表于') }}：{{ detailData.createTimeStr }}</div>
        </div>
      </div>
      <div class="content-html editor-content-view" v-html="detailData.contentHtml">
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api/index'

export default {
  name: 'NewsDetail',
  data () {
    return {
      newsNum: '',
      detailData: {}
    }
  },
  created () {
    const { newsNum } = this.$route.query
    this.newsNum = newsNum
    this.getDetail()
  },
  mounted () {
  },
  methods: {
    getDetail () {
      const { newsNum } = this
      api.getNewsDetail({
        newsNum
      }).then((res) => {
        this.detailData = res
      }).catch(console.error)
    }
  }
}
</script>

<style scoped lang="less">
.news-detail {
  padding-top: 98px;
  padding-bottom: 229px;
  background: #F7F8FA;
  .news-detail-content {
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    .content-top {
      padding: 40px 30px 7px 30px;
      border-bottom: 1px solid rgba(0,0,0,0.16);
      .content-top-title {
        text-align: center;
        font-size: 20px;
        font-weight: 400;
        color: #000000;
        line-height: 20px;
      }
      .content-top-footer {
        display: flex;
        justify-content: space-between;
        padding-top: 25px;
        font-size: 14px;
        color: #7C7C85;
        font-weight: 300;
        line-height: 30px;
        .content-top-footer-left {
          position: relative;
          padding-left: 40px;
          padding-right: 50px;
          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 30px;
            height: 30px;
            background: url('~assets/images/news/dafu.png') no-repeat center/cover;
          }
        }
        .content-top-footer-right {
          position: relative;
          padding-left: 36px;
          &:before {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            width: 16px;
            height: 16px;
            background: url('~assets/images/news/time.png') no-repeat center/cover;
          }
        }
      }
    }
    .content-html {
      padding: 40px 30px 60px;
    }
  }
}
@media screen and (max-width: @mobileWidth) {
  .news-detail {
    padding-top: 30px;
    padding-bottom: 80px;
    .news-detail-content {
      .content-top {
        padding: 20px 15px 9px 15px;
        .content-top-title {
          font-size: 16px;
          line-height: 28px;
        }
        .content-top-footer {
          padding-top: 23px;
          font-size: 10px;
          line-height: 20px;
          .content-top-footer-left {
            padding-left: 29px;
            padding-right: 10px;
            &:before {
              width: 20px;
              height: 20px;
            }
          }
          .content-top-footer-right {
            padding-left: 23px;
            &:before {
              width: 14px;
              height: 14px;
            }
          }
        }
      }
      .content-html {
        padding: 25px 15px 20px;
      }
    }
  }
}
</style>
